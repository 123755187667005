import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditTimeTables() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [AllTimeTables, setAllTimeTables] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        day: "",
        day_value: "",
        

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (AllTimeTables.id) {
        setFormInputs({
            id:id,
            day: AllTimeTables.day,
            day_value: AllTimeTables.day_value,
            
        });
        }
    }, [AllTimeTables]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const {
                id,
                day,
                day_value,
            } = formInputs;
        
            if (
                !id ||
                !day ||
                !day_value 
            ) {
                console.log(id,day,day_value)
                return alert("All fields are required");
            }
            
        
            try {
                const response = await axios.post("/update-time-tables", {
                    id,day,day_value
                });
            
                if (response.status === 200) {
                // Update AllNumbers state after a successful update
                setAllTimeTables((prevTimeTables) => ({
                    ...prevTimeTables,
                    day,day_value
                }));
            
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getTimeTable = (itemId) => {
        let table = "time_table";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAllTimeTables(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getTimeTable(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getTimeTable(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <h1> تعديل مواعيد العمل :  {formInputs.day}</h1>
                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> اليوم  </label>
                                <input value={formInputs.day}  onChange={handleChange} type='text' required="required"  maxLength="100" name='day'/>
                            </div>

                            
                            <div className='input'>
                                <label> الوقت  </label>
                                <input value={formInputs.day_value}  onChange={handleChange} type='text' required="required"  maxLength="100" name='day_value'/>
                            </div>


                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditTimeTables;
