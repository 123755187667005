import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';

function EditVideos() {
    const navigate = useNavigate();
    const [Videos, setVideos] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when the session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed

    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };

    const [formInputs, setFormInputs] = useState({
        video: null,
    });

    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (Videos.id) {
        setFormInputs({
            video: null,
        });
        }
    }, [Videos]);
    

    const handleChange = (e) => {
        handleInputChange();
        const { name, value, type } = e.target;
        const inputValue = type === "file" ? e.target.files[0] : value;
    
        setFormInputs((prevFormInputs) => ({
        ...prevFormInputs,
        [name]: inputValue,
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        let Loading = document.querySelector(".loading-screen");
        Loading.classList.add("active");

        const { video } = formInputs;

        try {
            const form = new FormData();
            form.append("id", id);
            form.append("video", video); // Use "video" as the field name


            const response = await axios.post("/update-video", form);

            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");

                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);
            }
        } catch (error) {
            console.error(error);
            alert("Server error");
        }
    };

    axios.defaults.withCredentials = true;

    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id === "") {
                    navigate('/admin-login');
                } else {
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getIfLogin();
    }, []);

    return (
        <>
            <div className='loading-screen'>
                <div className="loader">
                    <div className="outer"></div>
                    <div className="middle"></div>
                    <div className="inner"></div>
                </div>
            </div>
            <section className='dashboard'>
                <div className='edit-form'>
                    <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i className="las la-check-circle"></i></h1>
                    </div>
                    <div className='alert-danger'>
                        <h1>حدث خطأ أثناء تعديل البيانات <i className="las la-exclamation-triangle"></i></h1>
                    </div>
                    <h1> تعديل الفيديو </h1>
                    <form onSubmit={handleSubmit}>
                        <div className='input'>
                            <label> الفيديو  </label>
                            <input onChange={handleChange} type='file' name="video" accept="video/*" />
                        </div>
                        <button disabled={!formChanged} type='submit'> تحديث </button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default EditVideos;
