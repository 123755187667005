import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import Landing from '../Landing';




function AllTimeTables(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllTimeTables, setAllTimeTables] = useState([]);

    axios.defaults.withCredentials = true;





    const getAllTimeTables = ()=>{
        axios.post('AllItems', {
            table: 'time_table',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllTimeTables(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }




useEffect(() => {

    getAllTimeTables();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th> اليوم </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllTimeTables.length ? (

                        AllTimeTables.map(timetable => {

                        return (
                            <tr key={timetable.id}>
                            <td><h6>{timetable.id}</h6></td>

                            <td><h6>{timetable.day}</h6></td>







                            <td>
                                <Link className='edit' to={`/edit-time-table?id=${timetable.id}`}> <i class="las la-edit"></i> </Link>
                            </td>

                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllTimeTables;