import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditBenefits() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [AllBenefits, setAllBenefits] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        header: "",
        header_en: "",
        para: "",
        para_en: "",
        ben_head_one: "",
        ben_head_one_en: "",
        ben_para_one: "",
        ben_para_one_en: "",
        ben_head_two: "",
        ben_head_two_en: "",
        ben_para_two: "",
        ben_para_two_en: "",
        ben_head_three: "",
        ben_head_three_en: "",
        ben_para_three: "",
        ben_para_three_en: "",

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (AllBenefits.id) {
        setFormInputs({
            id:id,
            header: AllBenefits.header,
            header_en: AllBenefits.header_en,
            para: AllBenefits.para,
            para_en: AllBenefits.para_en,
            ben_head_one: AllBenefits.ben_head_one,
            ben_head_one_en: AllBenefits.ben_head_one_en,
            ben_para_one: AllBenefits.ben_para_one,
            ben_para_one_en: AllBenefits.ben_para_one_en,
            ben_head_two: AllBenefits.ben_head_two,
            ben_head_two_en: AllBenefits.ben_head_two_en,
            ben_para_two: AllBenefits.ben_para_two,
            ben_para_two_en: AllBenefits.ben_para_two_en,
            ben_head_three: AllBenefits.ben_head_three,
            ben_head_three_en: AllBenefits.ben_head_three_en,
            ben_para_three: AllBenefits.ben_para_three,
            ben_para_three_en: AllBenefits.ben_para_three_en,
        });
        }
    }, [AllBenefits]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const {
                id,
                header,
                header_en,
                para,
                para_en,
                ben_head_one,
                ben_head_one_en,
                ben_para_one,
                ben_para_one_en,
                ben_head_two,
                ben_head_two_en,
                ben_para_two,
                ben_para_two_en,
                ben_head_three,
                ben_head_three_en,
                ben_para_three,
                ben_para_three_en,
            } = formInputs;
        
            if (
                !id ||
                !header ||
                !header_en ||
                !para ||
                !para_en ||
                !ben_head_one ||
                !ben_head_one_en ||
                !ben_para_one ||
                !ben_para_one_en ||
                !ben_head_two ||
                !ben_head_two_en ||
                !ben_para_two ||
                !ben_para_two_en ||
                !ben_head_three ||
                !ben_head_three_en ||
                !ben_para_three ||
                !ben_para_three_en
            ) {
                console.log(id,  
                    header,
                    header_en,
                    para,
                    para_en,
                    ben_head_one,
                    ben_head_one_en,
                    ben_para_one,
                    ben_para_one_en,
                    ben_head_two,
                    ben_head_two_en,
                    ben_para_two,
                    ben_para_two_en,
                    ben_head_three,
                    ben_head_three_en,
                    ben_para_three,
                    ben_para_three_en,)
                return alert("All fields are required");
            }
            
        
            try {
                const response = await axios.post("/update-benefit", {
                id,
                header,
                header_en,
                para,
                para_en,
                ben_head_one,
                ben_head_one_en,
                ben_para_one,
                ben_para_one_en,
                ben_head_two,
                ben_head_two_en,
                ben_para_two,
                ben_para_two_en,
                ben_head_three,
                ben_head_three_en,
                ben_para_three,
                ben_para_three_en,
                });
            
                if (response.status === 200) {
                // Update AllBenefits state after a successful update
                setAllBenefits((prevNumbers) => ({
                    ...prevNumbers,
                    header,
                    header_en,
                    para,
                    para_en,
                    ben_head_one,
                    ben_head_one_en,
                    ben_para_one,
                    ben_para_one_en,
                    ben_head_two,
                    ben_head_two_en,
                    ben_para_two,
                    ben_para_two_en,
                    ben_head_three,
                    ben_head_three_en,
                    ben_para_three,
                    ben_para_three_en,
                }));
            
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getBenefit = (itemId) => {
        let table = "benefits";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAllBenefits(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getBenefit(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getBenefit(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <h1> تعديل الفوائد :  {formInputs.header}</h1>
                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> العنوان  </label>
                                <input value={formInputs.header}  onChange={handleChange} type='text' required="required"  maxLength="250" name='header'/>
                            </div>

                            
                            <div className='input'>
                                <label> العنوان بالانجليزية  </label>
                                <input value={formInputs.header_en}  onChange={handleChange} type='text' required="required"  maxLength="250" name='header_en'/>
                            </div>


                            
                            <div className='input'>
                                <label> النص  </label>
                                <textarea defaultValue={formInputs.para} name='para' required="required" onChange={handleChange} maxLength="1000">
                                    
                                </textarea>
                            </div>

                                    
                            <div className='input'>
                                <label> النص بالانجليزية  </label>
                                <textarea defaultValue={formInputs.para_en} name='para_en' required="required" onChange={handleChange} maxLength="1000">
                                    
                                </textarea>
                            </div>


                                
                            <div className='input'>
                                <label> عنوان الفائدة الاولى  </label>
                                <input  value={formInputs.ben_head_one}  onChange={handleChange} type='text' required="required"  maxLength="200" name='ben_head_one'/>
                            </div>

                            <div className='input'>
                                <label>    عنوان الفائدة الاولى  بالانجليزية </label>
                                <input  value={formInputs.ben_head_one_en}  onChange={handleChange} type='text' required="required"  maxLength="200" name='ben_head_one_en'/>
                            </div>


                            
                            <div className='input'>
                                <label> نص الفائدة الاولى  </label>
                                <input  value={formInputs.ben_para_one}  onChange={handleChange} type='text' required="required"  maxLength="500" name='ben_para_one'/>
                            </div>


                            <div className='input'>
                                <label>  نص الفائدة الاولى بالانجليزية </label>
                                <input  value={formInputs.ben_para_one_en}  onChange={handleChange} type='text' required="required"  maxLength="500" name='ben_para_one_en'/>
                            </div>


                                
                            <div className='input'>
                                <label> عنوان الفائدة الثانية  </label>
                                <input  value={formInputs.ben_head_two}  onChange={handleChange} type='text' required="required"  maxLength="200" name='ben_head_two'/>
                            </div>

                            <div className='input'>
                                <label>    عنوان الفائدة الثانية  بالانجليزية </label>
                                <input  value={formInputs.ben_head_two_en}  onChange={handleChange} type='text' required="required"  maxLength="200" name='ben_head_two_en'/>
                            </div>


                            
                            <div className='input'>
                                <label> نص الفائدة الثانية  </label>
                                <input  value={formInputs.ben_para_two}  onChange={handleChange} type='text' required="required"  maxLength="500" name='ben_para_two'/>
                            </div>


                            <div className='input'>
                                <label>  نص الفائدة الثانية بالانجليزية </label>
                                <input  value={formInputs.ben_para_two_en}  onChange={handleChange} type='text' required="required"  maxLength="500" name='ben_para_two_en'/>
                            </div>





                                
                            <div className='input'>
                                <label> عنوان الفائدة الثالثة  </label>
                                <input  value={formInputs.ben_head_three}  onChange={handleChange} type='text' required="required"  maxLength="200" name='ben_head_three'/>
                            </div>

                            <div className='input'>
                                <label>    عنوان الفائدة الثالثة  بالانجليزية </label>
                                <input  value={formInputs.ben_head_three_en}  onChange={handleChange} type='text' required="required"  maxLength="200" name='ben_head_three_en'/>
                            </div>


                            
                            <div className='input'>
                                <label> نص الفائدة الثالثة  </label>
                                <input  value={formInputs.ben_para_three}  onChange={handleChange} type='text' required="required"  maxLength="500" name='ben_para_three'/>
                            </div>


                            <div className='input'>
                                <label>  نص الفائدة الثالثة بالانجليزية </label>
                                <input  value={formInputs.ben_para_three_en}  onChange={handleChange} type='text' required="required"  maxLength="500" name='ben_para_three_en'/>
                            </div>





                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditBenefits;
